.input-fields {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 0px 20px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0px;
  justify-content: center;
}
.search-button {
  margin: 10px 0px;
}

.add-btn {
  margin-left: 10px;
  min-width: fit-content;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .input-fields {
    flex-direction: column;
  }
}
